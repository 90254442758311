import React from "react";
import { NavLink } from "react-router-dom";
import {
    Box,
    Button,
    ButtonGroup,
    Flex,
    Spacer,
    Text,
    Image,
    Link,
} from "@chakra-ui/react";
import { headerSocialButtons, headerTitle } from "../../store/data";

const AppHeader = () => {
    return (
        <>
            <Flex
                className="test"
                align="center"
                direction="column"
                display={{ lg: "block", sm: "block" }}
            >
                <Flex
                    align="center"
                    justifyContent="space-between"
                    direction={{
                        base: "row",
                        sm: "column",
                        lg: "row",
                        md: "row",
                    }}
                    width="100%"
                    mt={{ base: "61px", sm: "30px", md: "46px", lg: "61px" }}
                >
                    <Box>
                        <NavLink to="/works" exact="true">
                            {[...headerTitle].map((char, idx) => {
                                return (
                                    <Text
                                        fontWeight="600"
                                        fontSize="16px"
                                        lineHeight="20px"
                                        letterSpacing="0.1em"
                                        textTransform="uppercase"
                                        as="span"
                                        key={`${idx}-${char}`}
                                    >
                                        {char}
                                    </Text>
                                );
                            })}{" "}
                        </NavLink>
                    </Box>
                    <Spacer />
                    <Flex
                        className="buttons"
                        direction={{
                            sm: "column",
                            base: "row",
                            md: "row",
                            lg: "row",
                        }}
                        align="center"
                        justify="center"
                        mt={{ base: "0", sm: "24px", md: "0", lg: "0" }}
                    >
                        <Box className="internal-buttons">
                            <ButtonGroup variant="outline" spacing="6">
                                <NavLink to="/works" exact="true">
                                    <Button
                                        variant="link"
                                        size="md"
                                        boxShadow="0"
                                        colorScheme="0"
                                        _hover={{ color: "pink" }}
                                        textTransform="uppercase"
                                        fontSize="14px"
                                        lineHeight="20px"
                                        letterSpacing="0.1em"
                                    >
                                        Works
                                    </Button>
                                </NavLink>
                                <NavLink to="/about" exact="true">
                                    <Button
                                        size="md"
                                        variant="link"
                                        boxShadow="0"
                                        colorScheme="0"
                                        _hover={{ color: "pink" }}
                                        textTransform="uppercase"
                                        fontSize="14px"
                                        lineHeight="20px"
                                        letterSpacing="0.1em"
                                    >
                                        About
                                    </Button>
                                </NavLink>
                            </ButtonGroup>
                        </Box>
                        <Flex
                            className="external-buttons"
                            direction="row"
                            ml={{
                                base: "22px",
                                sm: "0",
                                md: "16px",
                                lg: "22px",
                            }}
                            mt={{
                                base: "0",
                                sm: "24px",
                                md: "6px",
                                lg: "0",
                            }}
                        >
                            {headerSocialButtons.map((button, idx) => {
                                return (
                                    <Link
                                        href={button.href}
                                        isExternal
                                        key={`${button.href}-${idx}`}
                                    >
                                        <Button
                                            ml="5px"
                                            variant="link"
                                            size="md"
                                            width="32px"
                                            borderRadius="2px"
                                            height="32px"
                                            bg="#EDEDED"
                                            _hover={{ bg: "pink" }}
                                            key={`${button.name}-${idx}-${button.href}`}
                                        >
                                            <Image
                                                src={button.img}
                                                key={`${button.name}-${idx}`}
                                            />
                                        </Button>
                                    </Link>
                                );
                            })}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
};

export default AppHeader;
