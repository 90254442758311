import sampleSize from "lodash.samplesize";
const unigraphs = [
  {
    path: "Unigraph #465",
    imageSrc: "/images/unigraphs/465.webp",
  },
  {
    path: "Unigraph #472",
    imageSrc: "/images/unigraphs/472.webp",
  },
  {
    path: "Unigraph #487",
    imageSrc: "/images/unigraphs/487.webp",
  },
  {
    path: "Unigraph #492",
    imageSrc: "/images/unigraphs/492.webp",
  },
  {
    path: "Unigraph #498",
    imageSrc: "/images/unigraphs/498.webp",
  },
  {
    path: "Unigraph #509",
    imageSrc: "/images/unigraphs/509.webp",
  },
  {
    path: "Unigraph #514",
    imageSrc: "/images/unigraphs/514.webp",
  },
  {
    path: "Unigraph #545",
    imageSrc: "/images/unigraphs/545.webp",
  },
  {
    path: "Unigraph #548",
    imageSrc: "/images/unigraphs/548.webp",
  },
  {
    path: "Unigraph #552",
    imageSrc: "/images/unigraphs/552.webp",
  },
];

const abstraordinals = [
  {
    path: "Abstraordinal1",
    imageSrc: "/images/abstraordinals/Abstraordinal1.webp",
  },
  {
    path: "Abstraordinal2",
    imageSrc: "/images/abstraordinals/Abstraordinal2.webp",
  },
  {
    path: "Abstraordinal3",
    imageSrc: "/images/abstraordinals/Abstraordinal3.webp",
  },
  {
    path: "Abstraordinal4",
    imageSrc: "/images/abstraordinals/Abstraordinal4.webp",
  },
  {
    path: "Abstraordinal5",
    imageSrc: "/images/abstraordinals/Abstraordinal5.webp",
  },
  {
    path: "Abstraordinal6",
    imageSrc: "/images/abstraordinals/Abstraordinal6.webp",
  },
  {
    path: "Abstraordinal7",
    imageSrc: "/images/abstraordinals/Abstraordinal7.webp",
  },
  {
    path: "Abstraordinal8",
    imageSrc: "/images/abstraordinals/Abstraordinal8.webp",
  },
  {
    path: "Abstraordinal9",
    imageSrc: "/images/abstraordinals/Abstraordinal9.webp",
  },
  {
    path: "Abstraordinal10",
    imageSrc: "/images/abstraordinals/Abstraordinal10.webp",
  },
  {
    path: "Abstraordinal11",
    imageSrc: "/images/abstraordinals/Abstraordinal11.webp",
  },
  {
    path: "Abstraordinal12",
    imageSrc: "/images/abstraordinals/Abstraordinal12.webp",
  },
  {
    path: "Abstraordinal13",
    imageSrc: "/images/abstraordinals/Abstraordinal13.webp",
  },
  {
    path: "Abstraordinal14",
    imageSrc: "/images/abstraordinals/Abstraordinal14.webp",
  },
  {
    path: "Abstraordinal15",
    imageSrc: "/images/abstraordinals/Abstraordinal15.webp",
  },
  {
    path: "Abstraordinal16",
    imageSrc: "/images/abstraordinals/Abstraordinal16.webp",
  },
  {
    path: "Abstraordinal17",
    imageSrc: "/images/abstraordinals/Abstraordinal17.webp",
  },
  {
    path: "Abstraordinal18",
    imageSrc: "/images/abstraordinals/Abstraordinal18.webp",
  },
  {
    path: "Abstraordinal19",
    imageSrc: "/images/abstraordinals/Abstraordinal19.webp",
  },
  {
    path: "Abstraordinal20",
    imageSrc: "/images/abstraordinals/Abstraordinal20.webp",
  },
  {
    path: "Abstraordinal21",
    imageSrc: "/images/abstraordinals/Abstraordinal21.webp",
  },
  {
    path: "Abstraordinal22",
    imageSrc: "/images/abstraordinals/Abstraordinal22.webp",
  },
  {
    path: "Abstraordinal23",
    imageSrc: "/images/abstraordinals/Abstraordinal23.webp",
  },
  {
    path: "Abstraordinal24",
    imageSrc: "/images/abstraordinals/Abstraordinal24.webp",
  },
  {
    path: "Abstraordinal25",
    imageSrc: "/images/abstraordinals/Abstraordinal25.webp",
  },
  {
    path: "Abstraordinal26",
    imageSrc: "/images/abstraordinals/Abstraordinal26.webp",
  },
  {
    path: "Abstraordinal27",
    imageSrc: "/images/abstraordinals/Abstraordinal27.webp",
  },
  {
    path: "Abstraordinal28",
    imageSrc: "/images/abstraordinals/Abstraordinal28.webp",
  },
  {
    path: "Abstraordinal29",
    imageSrc: "/images/abstraordinals/Abstraordinal29.webp",
  },
  {
    path: "Abstraordinal30",
    imageSrc: "/images/abstraordinals/Abstraordinal30.webp",
  },
  {
    path: "Abstraordinal31",
    imageSrc: "/images/abstraordinals/Abstraordinal31.webp",
  },
  {
    path: "Abstraordinal32",
    imageSrc: "/images/abstraordinals/Abstraordinal32.webp",
  },
  {
    path: "Abstraordinal33",
    imageSrc: "/images/abstraordinals/Abstraordinal33.webp",
  },
  {
    path: "Abstraordinal34",
    imageSrc: "/images/abstraordinals/Abstraordinal34.webp",
  },
  {
    path: "Abstraordinal35",
    imageSrc: "/images/abstraordinals/Abstraordinal35.webp",
  },
  {
    path: "Abstraordinal36",
    imageSrc: "/images/abstraordinals/Abstraordinal36.webp",
  },
  {
    path: "Abstraordinal37",
    imageSrc: "/images/abstraordinals/Abstraordinal37.webp",
  },
  {
    path: "Abstraordinal38",
    imageSrc: "/images/abstraordinals/Abstraordinal38.webp",
  },
  {
    path: "Abstraordinal39",
    imageSrc: "/images/abstraordinals/Abstraordinal39.webp",
  },
  {
    path: "Abstraordinal40",
    imageSrc: "/images/abstraordinals/Abstraordinal40.webp",
  },
  {
    path: "Abstraordinal41",
    imageSrc: "/images/abstraordinals/Abstraordinal41.webp",
  },
  {
    path: "Abstraordinal42",
    imageSrc: "/images/abstraordinals/Abstraordinal42.webp",
  },
  {
    path: "Abstraordinal43",
    imageSrc: "/images/abstraordinals/Abstraordinal43.webp",
  },
  {
    path: "Abstraordinal44",
    imageSrc: "/images/abstraordinals/Abstraordinal44.webp",
  },
  {
    path: "Abstraordinal45",
    imageSrc: "/images/abstraordinals/Abstraordinal45.webp",
  },
  {
    path: "Abstraordinal46",
    imageSrc: "/images/abstraordinals/Abstraordinal46.webp",
  },
  {
    path: "Abstraordinal47",
    imageSrc: "/images/abstraordinals/Abstraordinal47.webp",
  },
  {
    path: "Abstraordinal48",
    imageSrc: "/images/abstraordinals/Abstraordinal48.webp",
  },
  {
    path: "Abstraordinal49",
    imageSrc: "/images/abstraordinals/Abstraordinal49.webp",
  },
  {
    path: "Abstraordinal50",
    imageSrc: "/images/abstraordinals/Abstraordinal50.webp",
  },
  {
    path: "Abstraordinal51",
    imageSrc: "/images/abstraordinals/Abstraordinal51.webp",
  },
  {
    path: "Abstraordinal52",
    imageSrc: "/images/abstraordinals/Abstraordinal52.webp",
  },
  {
    path: "Abstraordinal53",
    imageSrc: "/images/abstraordinals/Abstraordinal53.webp",
  },
  {
    path: "Abstraordinal54",
    imageSrc: "/images/abstraordinals/Abstraordinal54.webp",
  },
  {
    path: "Abstraordinal55",
    imageSrc: "/images/abstraordinals/Abstraordinal55.webp",
  },
  {
    path: "Abstraordinal56",
    imageSrc: "/images/abstraordinals/Abstraordinal56.webp",
  },
  {
    path: "Abstraordinal57",
    imageSrc: "/images/abstraordinals/Abstraordinal57.webp",
  },
  {
    path: "Abstraordinal58",
    imageSrc: "/images/abstraordinals/Abstraordinal58.webp",
  },
  {
    path: "Abstraordinal59",
    imageSrc: "/images/abstraordinals/Abstraordinal59.webp",
  },
  {
    path: "Abstraordinal60",
    imageSrc: "/images/abstraordinals/Abstraordinal60.webp",
  },
  {
    path: "Abstraordinal61",
    imageSrc: "/images/abstraordinals/Abstraordinal61.webp",
  },
  {
    path: "Abstraordinal62",
    imageSrc: "/images/abstraordinals/Abstraordinal62.webp",
  },
  {
    path: "Abstraordinal63",
    imageSrc: "/images/abstraordinals/Abstraordinal63.webp",
  },
  {
    path: "Abstraordinal64",
    imageSrc: "/images/abstraordinals/Abstraordinal64.webp",
  },
  {
    path: "Abstraordinal65",
    imageSrc: "/images/abstraordinals/Abstraordinal65.webp",
  },
  {
    path: "Abstraordinal66",
    imageSrc: "/images/abstraordinals/Abstraordinal66.webp",
  },
  {
    path: "Abstraordinal67",
    imageSrc: "/images/abstraordinals/Abstraordinal67.webp",
  },
  {
    path: "Abstraordinal68",
    imageSrc: "/images/abstraordinals/Abstraordinal68.webp",
  },
  {
    path: "Abstraordinal69",
    imageSrc: "/images/abstraordinals/Abstraordinal69.webp",
  },
  {
    path: "Abstraordinal70",
    imageSrc: "/images/abstraordinals/Abstraordinal70.webp",
  },
  {
    path: "Abstraordinal71",
    imageSrc: "/images/abstraordinals/Abstraordinal71.webp",
  },
  {
    path: "Abstraordinal72",
    imageSrc: "/images/abstraordinals/Abstraordinal72.webp",
  },
  {
    path: "Abstraordinal73",
    imageSrc: "/images/abstraordinals/Abstraordinal73.webp",
  },
  {
    path: "Abstraordinal74",
    imageSrc: "/images/abstraordinals/Abstraordinal74.webp",
  },
  {
    path: "Abstraordinal75",
    imageSrc: "/images/abstraordinals/Abstraordinal75.webp",
  },
  {
    path: "Abstraordinal76",
    imageSrc: "/images/abstraordinals/Abstraordinal76.webp",
  },
  {
    path: "Abstraordinal77",
    imageSrc: "/images/abstraordinals/Abstraordinal77.webp",
  },
  {
    path: "Abstraordinal78",
    imageSrc: "/images/abstraordinals/Abstraordinal78.webp",
  },
  {
    path: "Abstraordinal79",
    imageSrc: "/images/abstraordinals/Abstraordinal79.webp",
  },
  {
    path: "Abstraordinal80",
    imageSrc: "/images/abstraordinals/Abstraordinal80.webp",
  },
  {
    path: "Abstraordinal81",
    imageSrc: "/images/abstraordinals/Abstraordinal81.webp",
  },
  {
    path: "Abstraordinal82",
    imageSrc: "/images/abstraordinals/Abstraordinal82.webp",
  },
  {
    path: "Abstraordinal83",
    imageSrc: "/images/abstraordinals/Abstraordinal83.webp",
  },
  {
    path: "Abstraordinal84",
    imageSrc: "/images/abstraordinals/Abstraordinal84.webp",
  },
  {
    path: "Abstraordinal85",
    imageSrc: "/images/abstraordinals/Abstraordinal85.webp",
  },
  {
    path: "Abstraordinal86",
    imageSrc: "/images/abstraordinals/Abstraordinal86.webp",
  },
  {
    path: "Abstraordinal87",
    imageSrc: "/images/abstraordinals/Abstraordinal87.webp",
  },
  {
    path: "Abstraordinal88",
    imageSrc: "/images/abstraordinals/Abstraordinal88.webp",
  },
  {
    path: "Abstraordinal89",
    imageSrc: "/images/abstraordinals/Abstraordinal89.webp",
  },
  {
    path: "Abstraordinal90",
    imageSrc: "/images/abstraordinals/Abstraordinal90.webp",
  },
  {
    path: "Abstraordinal91",
    imageSrc: "/images/abstraordinals/Abstraordinal91.webp",
  },
  {
    path: "Abstraordinal92",
    imageSrc: "/images/abstraordinals/Abstraordinal92.webp",
  },
  {
    path: "Abstraordinal93",
    imageSrc: "/images/abstraordinals/Abstraordinal93.webp",
  },
  {
    path: "Abstraordinal94",
    imageSrc: "/images/abstraordinals/Abstraordinal94.webp",
  },
  {
    path: "Abstraordinal95",
    imageSrc: "/images/abstraordinals/Abstraordinal95.webp",
  },
  {
    path: "Abstraordinal96",
    imageSrc: "/images/abstraordinals/Abstraordinal96.webp",
  },
  {
    path: "Abstraordinal97",
    imageSrc: "/images/abstraordinals/Abstraordinal97.webp",
  },
  {
    path: "Abstraordinal98",
    imageSrc: "/images/abstraordinals/Abstraordinal98.webp",
  },
  {
    path: "Abstraordinal99",
    imageSrc: "/images/abstraordinals/Abstraordinal99.webp",
  },
  {
    path: "Abstraordinal100",
    imageSrc: "/images/abstraordinals/Abstraordinal100.webp",
  },
];

const initialData = [
  {
    headerText: "Unigraphs",
    links: [
      {
        link: "https://magiceden.io/ordinals/marketplace/unigraphs",
        icon: "icons/magic-eden.svg",
      },
    ],
    items: [...sampleSize(unigraphs, 8), { type: 'link', href: 'https://mulan.art/gallery/unigraphs', aspectRatio: '1/1' }],
  },
  {
    headerText: "Abstraordinals",
    links: [
      {
        link: "https://magiceden.io/ordinals/marketplace/abstraordinals",
        icon: "icons/magic-eden.svg",
      },
    ],
    items: [...sampleSize(abstraordinals, 9), { type: 'link', href: 'https://mulan.art/gallery/abstraordinals', aspectRatio: '7/10' }],
  },
  {
    headerText: "Exploration",
    links: [
      {
        link: "https://opensea.io/collection/explorations-2",
        icon: "icons/opensea-icon.svg",
      },
      {
        link: "https://foundation.app/collection/mulan-explorations",
        icon: "icons/foundation-icon.svg",
      },
    ],
    items: [
      {
        path: "artists-world",
        imageName: "Artist's World",
        imageSrc: "/images/explorations/artists-world.webp",
        customSize: { width: "550px", height: null },
      },
      {
        path: "uncertainty",
        imageName: "uncertainty",
        imageSrc: "/images/explorations/uncertainty.webp",
      },
      {
        path: "mountain-highway",
        imageName: "mountain highway",
        imageSrc: "/images/explorations/mountain-highway.webp",
        customSize: { width: "550px", height: null },
      },
      {
        path: "art-or-exit",
        imageName: "art or exit?",
        imageSrc: "/images/explorations/art-or-exit.webp",
      },
    ],
  },
];

export const headerSocialButtons = [
  {
    name: "twitter",
    href: "https://twitter.com/mulan_art",
    img: "/icons/twitter-icon.svg",
  },
  {
    name: "discord",
    href: "https://discord.gg/Gf7sXET4va",
    img: "/icons/discord-icon.svg",
  },
];

export const headerTitle = "MULAN.ART";

export const data = Object.freeze(initialData);
