import {
    Box,
    Button,
    ButtonGroup,
    Flex,
    Image,
    Link,
    Stack,
    Text,
} from "@chakra-ui/react";

import AppFooter from "../../components/UI/app-footer";
import AppHeader from "../../components/UI/app-header";
import React from "react";

const LinksPage = () => {
    return (
        <>
            <AppHeader />
            <Flex
                align="center"
                direction="column"
                mt={{ base: "100px", sm: "60px", md: "80px", lg: "100px" }}
            >
                <Box>
                    <Image src="/images/links-page-img.png" alt="img" />
                </Box>
                <Box>
                    <Text
                        fontSize="16px"
                        lineHeight="20px"
                        fontWeight="bold"
                        textTransform="uppercase"
                        letterSpacing="0.1em"
                        m={{ base: [0, 6], sm: [0, 8], md: [0, 6], lg: [0, 6] }}
                    >
                        @mulan.art
                    </Text>
                </Box>
                <ButtonGroup>
                    <Stack spacing={6} align="center" direction="column">
                        <Link
                            href="https://magiceden.io/ordinals/marketplace/abstraordinals"
                            isExternal
                        >
                            <Button
                                variant="solid"
                                width={{
                                    base: "480px",
                                    sm: "240px",
                                    md: "360px",
                                    lg: "480px",
                                }}
                                height="48px"
                                boxShadow="0"
                                color="#FFFFFF"
                                rounded="none"
                                backgroundColor="black"
                                _hover={{ color: "lightPink" }}
                                _active={{ color: "none" }}
                            >
                                Abstraordinals
                            </Button>
                        </Link>
                        <Link
                            href="https://magiceden.io/ordinals/marketplace/unigraphs"
                            isExternal
                        >
                            <Button
                                variant="solid"
                                width={{
                                    base: "480px",
                                    sm: "240px",
                                    md: "360px",
                                    lg: "480px",
                                }}
                                height="48px"
                                boxShadow="0"
                                color="#FFFFFF"
                                rounded="none"
                                backgroundColor="black"
                                _hover={{ color: "lightPink" }}
                                _active={{ color: "none" }}
                            >
                                Unigraphs
                            </Button>
                        </Link>
                        <Link href="https://mulan.art" isExternal>
                            <Button
                                variant="solid"
                                width={{
                                    base: "480px",
                                    sm: "240px",
                                    md: "360px",
                                    lg: "480px",
                                }}
                                height="48px"
                                boxShadow="0"
                                color="#FFFFFF"
                                rounded="none"
                                backgroundColor="#000000"
                                _hover={{ color: "lightPink" }}
                                _active={{ color: "none" }}
                            >
                                Website
                            </Button>
                        </Link>
                    </Stack>
                </ButtonGroup>
            </Flex>
            <AppFooter />
        </>
    );
};

export default LinksPage;
