import { Flex } from "@chakra-ui/react";
import React from "react";
import WorksSectionHeader from "./works-section-header";
import WorksTableItem from "./works-table-item";

const WorksTableSection = ({ headerText, items, links }) => {
    return (
        <div className="flex flex-col flex-wrap">
            <WorksSectionHeader headerText={headerText} links={links} />
            <Flex
                mt={{ base: "55px", sm: "24px", md: "38px", lg: "55px" }}
                wrap="wrap"
            >
                {items.map((item, i) => {
                    return <WorksTableItem key={i} item={item} />;
                })}
            </Flex>
        </div>
    );
};

export default WorksTableSection;
